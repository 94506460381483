<template>
  <div>
    <div class="workplatform-title">{{ brand.name }} </div>
     <Row class="p-b-5 p-t-10">
          <i-col span="12" ><span class="title">品牌行业 </span >{{brand.industryName}}</i-col >
        </Row>

    <!--  -->
     <h3>客户基本信息</h3>
        <Row class="p-b-5 p-t-10">
          <i-col span="24" ><span class="title">客户名称 </span >{{advertiser.name}}</i-col >
          <i-col span="12" ><span class="title">客户类型 </span >{{advertiser.typeName}}</i-col >
          <i-col span="12" ><span class="title">所属媒介主 </span >{{advertiser.publisherName}}</i-col >
          <i-col span="12" ><span class="title">联系电话 </span >{{advertiser.tel}}</i-col >
          <i-col span="12" ><span class="title">报备代理商 </span >{{advertiser.reportCompanyName}}</i-col >
          <i-col span="12" ><span class="title">报备时间 </span >{{advertiser.createTime}}</i-col >
          <!-- <i-col span="8" ><span class="title">地址 </span >{{advertiser.address}}</i-col > -->
          <i-col span="12"><span class="title">客户备注信息 </span>{{advertiser.description}}</i-col>
        </Row>

  </div>
</template>

<script>
import { getAdvertiser } from '@/api/crm/advertiser'
import { getBrandDetail } from '@/api/crm/brand'

export default {
  props: {
    id: {
      type: Number
    }
  },
  data () {
    return {
      brand: {},
      advertiser: {}
    }
  },
  methods: {
    initBrand () {
      getBrandDetail({ brandId: this.detailId }).then(res => {
        this.brand = res
        this.initAdvertiserInfo(res.advertiserId)
      })
    },
    initAdvertiserInfo (advertiserId) {
      getAdvertiser({ advertiserId: advertiserId }).then(res => {
        this.advertiser = res
      })
    }
  },
  created () {
    this.initBrand()
  },
  computed: {
    detailId () {
      return this.id || this.$store.state.advertiser.detailId
    }
  }
}
</script>
